/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client';
import type React from 'react';
import { Persistor, type PersistorProps } from './Persistor';

export const SiteFooterEmbedPersistor: React.FC<
  {
    children: React.ReactNode;
  } & Pick<PersistorProps, 'strategy'>
> = ({ children, strategy }) => {
  return (
    <Persistor
      selector="#site-footer-embed"
      getShadowRoot={() => {
        return document.getElementById('vcc-site-footer')?.shadowRoot;
      }}
      setup={() => {
        requestAnimationFrame(() => {
          //@ts-ignore
          window.siteFooterController?.reset?.();

          requestAnimationFrame(() => {
            //@ts-ignore
            window.siteFooterController?.callEntries?.();
          });
        });
      }}
      strategy={strategy}
    >
      {children}
    </Persistor>
  );
};
