/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client';
import { ROOT_ID } from '@volvo-cars/site-nav-embed/src/constants';
import type React from 'react';
import { Persistor, type PersistorProps } from './Persistor';

export const SiteNavigationEmbedPersistor: React.FC<
  {
    children: React.ReactNode;
  } & Pick<PersistorProps, 'strategy'>
> = ({ children, strategy }) => {
  return (
    <Persistor
      selector="#site-nav-embed"
      getShadowRoot={() => {
        return document.getElementById(ROOT_ID)?.shadowRoot;
      }}
      setup={() => {
        requestAnimationFrame(() => {
          window.siteNavigationController?.reset?.();

          requestAnimationFrame(() => {
            //@ts-ignore
            window.siteNavigationController?.callEntries?.();
          });
        });
      }}
      strategy={strategy}
    >
      {children}
    </Persistor>
  );
};
