'use client';
import type { SiteNavigationController } from '@volvo-cars/site-nav-embed';
import React, { useEffect, useRef } from 'react';
import { EmbedPersistorHandler } from './EmbedPersistorHandler';
import {
  type Style,
  createStyleSheetObserver,
} from './createStyleSheetObserver';

export interface PersistorProps {
  children: React.ReactNode;
  selector: string;
  setup: () => void;
  getShadowRoot: () => ShadowRoot | undefined | null;
  /**
   * Determines when the embed should be cloned and cached.
   *
   *  - `eager` [requires `EmbedPerisitorEagerScript` to be added to head]: The embed will be cloned and cached in an inline script
   *  immidiately after the embed component HTML is rendered. While this is useful to cache the embed
   * as soon as possible to recover from app hydration errors, it blocks the main
   * thread until clone is complete.
   *
   *  - `lazy` [default]: The embed will be cloned and cached after hydration
   *
   * @default 'lazy'
   */
  strategy?: 'eager' | 'lazy';
}

export const Persistor: React.FC<PersistorProps> = ({
  children,
  selector,
  setup,
  strategy = 'lazy',
  getShadowRoot,
}) => {
  const embedClone = useRef<ChildNode | null | undefined>(null);
  const [embedRootReadyKey, setEmbedRootReadyKey] = React.useState(1);
  const stylesheets = useRef<Style[]>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Intentional; we only want to run this once
  useEffect(() => {
    const embedElement = getEmbedElement(selector);

    if (!embedElement) {
      return;
    }

    const deepClone = EmbedPersistorHandler.deepClone;

    if (!embedClone.current) {
      const clone =
        strategy === 'eager'
          ? window.__EMBED_PERSISTOR__?.[selector]?.clone ||
            deepClone(embedElement)
          : deepClone(embedElement);

      embedClone.current = clone?.result;
      if (clone?.linkedStyleSheets) {
        for (const link of clone.linkedStyleSheets) {
          replaceLinkStyleSheetWithStyleTag(link);
        }
      }
    }

    if (!embedClone.current) {
      return;
    }

    function handleReplace() {
      try {
        if (!embedClone.current) {
          return;
        }

        const newElement = getEmbedElement(selector) as HTMLElement | undefined;

        if (!newElement) {
          return;
        }

        const clone = deepClone(embedClone.current);

        if (!clone?.result) {
          return;
        }

        const newClone = clone.result;

        const styles = stylesheets.current.map((style) => {
          if (style.type === 'stylesheet') {
            const linkElement = document.createElement('link');
            const attributes = style.attributes;

            for (const key of Object.keys(attributes)) {
              linkElement.setAttribute(key, attributes[key]);
            }

            linkElement.setAttribute('data-embed-persistor', '');

            return linkElement;
          }

          const styleElement = document.createElement('style');
          const attributes = style.attributes;

          for (const key of Object.keys(attributes)) {
            styleElement.setAttribute(key, attributes[key]);
          }
          styleElement.textContent = style.content;

          styleElement.setAttribute('data-embed-persistor', '');

          return styleElement;
        });

        newElement.replaceChildren(...Array.from(newClone.childNodes));

        const shadowRoot = getShadowRoot();

        if (!shadowRoot) return;

        shadowRoot.prepend(...styles);

        requestAnimationFrame(() => {
          setup();
          setEmbedRootReadyKey((prev) => prev + 1);
        });
      } catch (error) {
        console.error('Failed to replace embed', error);
      }
    }

    const currentPersistId = embedElement.getAttribute('data-persist-id');
    const initialPersistId =
      window.__EMBED_PERSISTOR__?.[selector]?.initialPersistId;
    const tag = window.__EMBED_PERSISTOR__?.[selector]?.tag;

    if (strategy === 'eager' && initialPersistId && currentPersistId && tag) {
      // Hydration errors cause the initial reference to be lost
      // as React replaces the initial reference with a new one

      //@ts-expect-error not typed
      const isTagged = embedElement[tag];

      if (currentPersistId !== initialPersistId || !isTagged) {
        handleReplace();
      }
    }

    const observer = new MutationObserver((mutations) => {
      for (const mutation of mutations) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-persist-id'
        ) {
          handleReplace();
        }
      }
    });

    observer.observe(embedElement, {
      attributes: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [selector]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Intentional; we only want to run this when embedRootReadyKey changes
  useEffect(() => {
    const documentRoot = getShadowRoot();
    const embedElement = getEmbedElement(selector);

    if (!documentRoot || !embedElement) return;

    const styleSheetObserver = createStyleSheetObserver((stylesheet) => {
      stylesheets.current.push(stylesheet);
    }, documentRoot);

    return () => styleSheetObserver.disconnect();
  }, [embedRootReadyKey, selector]);
  return (
    <>
      {children}
      {strategy === 'eager' ? (
        <script
          data-embed-persistor-for={selector}
          suppressHydrationWarning
          // biome-ignore lint/security/noDangerouslySetInnerHtml: really?
          dangerouslySetInnerHTML={{
            __html: `"__EMBED_PERSISTOR__"in window&&"EmbedPersistorHandler"in window.__EMBED_PERSISTOR__?new window.__EMBED_PERSISTOR__.EmbedPersistorHandler("${selector}"):console.error("EmbedPersistorHandler not found, make sure you have the EmbedPersistorEagerScript component included in your document head");`,
          }}
        />
      ) : null}
    </>
  );
};

function getEmbedElement(selector: string) {
  return document.querySelector(selector);
}

declare global {
  interface Window {
    siteNavigationController?: SiteNavigationController;
    __EMBED_PERSISTOR__?: {
      [key: string]: {
        clone?: { result: ChildNode | null; linkedStyleSheets: Node[] };
        initialPersistId?: string | null;
        tag?: symbol;
      };
    };
  }
}

/**
 * To prevent a flash of content when stylesheets are added to a shadow DOM,
 * replace non-design-system stylesheet links with inline style tags.
 * This avoids the browser re-loading the stylesheets when they are removed
 * and re-added to the new shadow DOM.
 *
 * This doesn't happen for links that already exist in the document out side of
 * any shadow DOM element, like the design-system stylesheets that are loaded
 * in the document head by the app.
 */
async function replaceLinkStyleSheetWithStyleTag(node: Node) {
  if (!(node instanceof HTMLLinkElement)) return node;

  try {
    const response = await fetch(node.href);

    if (!response.ok) {
      throw new Error(`HTTP ${response.status} loading ${node.href}`);
    }

    const css = await response.text();
    const style = document.createElement('style');

    for (const attr of Array.from(node.attributes)) {
      if (!['href', 'rel'].includes(attr.name)) {
        style.setAttribute(attr.name, attr.value);
      }
    }

    style.textContent = css;
    style.setAttribute('data-href', node.href);
    node.replaceWith(style);
    return style;
  } catch {
    return node;
  }
}
