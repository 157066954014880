export class EmbedPersistorHandler {
  embedSelector: string;

  constructor(embedSelector: string) {
    this.embedSelector = embedSelector;
    this.setupEmbedPersistor();
  }

  setupEmbedPersistor() {
    if (!('__EMBED_PERSISTOR__' in window)) {
      Object.defineProperty(window, '__EMBED_PERSISTOR__', {
        value: {},
        enumerable: true,
        writable: false,
        configurable: true,
      });
    }

    if (window.__EMBED_PERSISTOR__?.[this.embedSelector]?.clone) {
      return;
    }

    try {
      const element = document.querySelector(this.embedSelector);

      if (!element) {
        console.error(
          `Embed element with id "${this.embedSelector}" not found`,
        );
        return;
      }

      const initialPersistId = element.getAttribute('data-persist-id');
      const clone = EmbedPersistorHandler.deepClone(element);
      const tag = Symbol('EmbedPersistorHandler');

      //@ts-expect-error not typed
      element[tag] = true;

      if (window.__EMBED_PERSISTOR__?.[this.embedSelector]) {
        window.__EMBED_PERSISTOR__[this.embedSelector].clone = clone;
        window.__EMBED_PERSISTOR__[this.embedSelector].initialPersistId =
          initialPersistId;
        window.__EMBED_PERSISTOR__[this.embedSelector].tag = tag;
      } else {
        Object.defineProperty(window.__EMBED_PERSISTOR__, this.embedSelector, {
          value: { clone, initialPersistId, tag },
          enumerable: true,
          writable: false,
          configurable: true,
        });
      }
    } catch (error) {
      console.error('Failed to setup embed persistor', error);
    }
  }

  static deepClone(host: Element | ChildNode) {
    // Handle cases where a very deeply nested tree with potential cirucular
    // references can cause the clone to hang indefinitely
    const controller = new AbortController();

    const timeout = setTimeout(() => {
      controller.abort();
      // 20x CPU slow down takes around 300ms to complete
      // so this is a reasonable timeout
    }, 500);

    try {
      const fragment = document.createDocumentFragment();
      const queue: [Node, Node | DocumentFragment][] = [[host, fragment]];
      let index = 0;
      const linkedStyleSheets: Node[] = [];

      const errorMessage = 'Deep clone took more than 500ms so it was aborted';
      while (index < queue.length) {
        if (controller.signal.aborted) {
          throw new Error(errorMessage);
        }

        const [originalNode, clonedParent] = queue[index++];
        let clone: Node;

        if (originalNode instanceof HTMLScriptElement) {
          clone = EmbedPersistorHandler.cloneScript(originalNode);
        } else if (
          originalNode instanceof HTMLLinkElement &&
          originalNode.rel === 'stylesheet'
        ) {
          const linkedStylesheet = originalNode.cloneNode(false);
          // Replace non-design-system stylesheet links with style tags
          // to prevent the browser from re-loading them
          // when they are removed and re-added to the new shadow dom
          // avoiding flash of content
          if (!originalNode.getAttribute('data-volvo-css-name')) {
            linkedStyleSheets.push(linkedStylesheet);
          }
          clone = linkedStylesheet;
        } else {
          clone = originalNode.cloneNode(false); // Shallow clone
        }

        clonedParent.appendChild(clone);

        // Handle shadow DOM if present
        if (originalNode instanceof Element && originalNode.shadowRoot) {
          const shadowClone = (clone as Element).attachShadow({ mode: 'open' });
          let shadowChild = originalNode.shadowRoot.firstChild;
          while (shadowChild) {
            if (controller.signal.aborted) {
              throw new Error(`${errorMessage}: too many shadow children`);
            }
            queue.push([shadowChild, shadowClone]);
            shadowChild = shadowChild.nextSibling;
          }
        }

        // Process light DOM children if not a script
        if (!(originalNode instanceof HTMLScriptElement)) {
          let lightChild = originalNode.firstChild;
          while (lightChild) {
            if (controller.signal.aborted) {
              throw new Error(`${errorMessage}: too many light children`);
            }

            queue.push([lightChild, clone]);
            lightChild = lightChild.nextSibling;
          }
        }
      }

      clearTimeout(timeout);
      return { result: fragment.firstChild, linkedStyleSheets };
    } catch (error) {
      clearTimeout(timeout);
      if (controller.signal.aborted) {
        console.error(error);
      } else {
        throw error;
      }
    }
  }

  static cloneScript(script: HTMLScriptElement) {
    const newScript = document.createElement('script');

    for (const attr of script.attributes) {
      newScript.setAttribute(attr.name, attr.value);
    }

    // Handle inline scripts
    if (!script.src && script.textContent) {
      newScript.textContent = script.textContent;
    }

    return newScript;
  }
}
